<template>
  <div class="">
    <div class="form-row">
      <sgv-input-file
        class="col-12"
        ref="fileUpload"
        label="ไฟล์ CSV"
        @change="changeToJSON($event)">
      </sgv-input-file>
    </div>

    <button
      class="btn btn-primary"
      type="button"
      @click="reset">
      ยกเลิก
    </button>

    <sgv-csv
      :items="csvData"
      :labels="csvLabels"
      :filename="csvFilename">
      <button
        type="button"
        class="btn btn-info ml-2">
        ตัวอย่าง CSV
      </button>
    </sgv-csv>

    <div class="mt-3" v-if="errorRows.length > 0">
      <span class="text-danger">Error:</span>
      <sgv-table
        :items="errorRows"
        :headers="headers"
        :options.sync="options"
        headerless>

        <template slot-scope="{item, hidden}">
          <tr class="">
            <td v-if="hidden.name">{{item.name}}</td>
            <td v-if="hidden.description">{{item.description}}</td>
          </tr>
        </template>
      </sgv-table>
    </div>

    <div class="mt-3" v-if="addRows.length > 0">
      <button
        type="button"
        class="btn btn-success btn-sm mb-2"
        @dblclick="addAll">
        เพิ่มทั้งหมด
      </button>
      <sgv-table
        :items="addRows"
        :headers="headers"
        :options.sync="options"
        headerless>

        <template slot-scope="{item, hidden}">
          <tr class="">
            <td v-if="hidden.name">
              <button
                type="button"
                class="btn btn-success btn-sm mr-1"
                @click="addData(item)">
                เพิ่ม
              </button>
              {{item.name}}
            </td>
            <td v-if="hidden.description">
              {{item.description}}
              <ul>
                <li>{{item.machine.code}} ({{item.machine.name}})</li>
                <li>{{item.procedureInventory.code}} ({{item.procedureInventory.name}})</li>
                <li>{{item.procedureControl.code}} ({{item.procedureControl.name}})</li>
                <li>{{item.procedureQuality.code}} ({{item.procedureQuality.name}})</li>
              </ul>
            </td>
          </tr>
        </template>
      </sgv-table>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
import retainMixin from '@/mixins/retain-mixin'
import {
  LIST_IMPORT_VALIDATION,
  CREATE_PROCEDURE,
} from './graph'

export default {
  mixins: [retainMixin],
  props: {
    procedureType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rKey: `Procedure${this.$form.capitalize(this.procedureType)}ListImport`,
      headers: [
        {text: 'ชื่อ', value: 'name'},
        {text: 'รายละเอียด', value: 'description'}
      ],
      options: {
        headers: ['name', 'description'],
        column: null,
        search: null,
        toolbar: null,
      },
      csvFilename: 'ตัวอย่างนำเข้าการผลิต',
      csvLabels: {
        name: { title: 'name' },
        description: { title: 'description' },
        machineCode: { title: 'machineCode' },
        procedureInventoryCode: { title: 'procedureInventoryCode' },
        procedureControlCode: { title: 'procedureControlCode' },
        procedureQualityCode: { title: 'procedureQualityCode' },
      },
      csvData: [
        {
          name: 'ชื่อ',
          description: 'รายละเอียด',
          machineCode: 'รหัสเครื่องจักร',
          procedureInventoryCode: 'รหัสควบคุมสินค้า',
          procedureControlCode: 'รหัสควบคุมผลิต',
          procedureQualityCode: 'รหัสควบคุมคุณภาพ',
        },
        {
          name: 'การผลิต Apple (A01)',
          description: '',
          machineCode: 'MAC-01',
          procedureInventoryCode: 'PI-A01',
          procedureControlCode: 'PC-A01',
          procedureQualityCode: 'PQ-A01'
        },
      ],
      rawInputs: [],
      errorRows: [],
      addRows: []
    }
  },
  methods: {
    changeToJSON (e) {
      var fileInput = e.target.files[0]
      const options = {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          const arr = results.data.slice(1)
          this.rawInputs = arr
          this.getValidation()
        }
      }
      this.$papa.parse(fileInput, options)
    },
    reset () {
      this.setDefault ()
      this.$refs.fileUpload.reset()
    },
    getValidation: debounce(function() {
      this.errorRows = []
      this.addRows = []

      this.$apollo.query({
        query: LIST_IMPORT_VALIDATION(this.templateType),
        variables: {
          procedureType: this.procedureType,
          input: this.rawInputs
        },
        fetchPolicy: 'no-cache',
      })
      .then(res => {
        this.errorRows = res.data.result?.errors || []
        this.addRows = res.data.result?.adds || []
      })
      .catch(err => {
        this.setDefault ()
        this.$toasted.global.error(err)
      })
    }, 300),
    setDefault() {
      this.errorRows = []
      this.addRows = []
    },
    addData (item) {
      const formData = {
        name: item.name,
        description: item.description,
        machineId: item.machineId,
        procedureInventoryId: item.procedureInventoryId,
        procedureControlId: item.procedureControlId,
        procedureQualityId: item.procedureQualityId
      }

      this.$apollo.mutate({
        mutation: CREATE_PROCEDURE(this.templateType),
        variables: {
          procedureType: this.procedureType,
          input: formData
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.getValidation()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    async addAll () {
      const items = this.addRows
      for (let i = 0; i < items.length; i++) {
        await this.addData(items[i])
      }
    },
  }
}
</script>

<style lang="css" scoped>
</style>
