<template>
  <sgv-table
    :rKey="rKey"
    :items="procedures"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options">
    <template slot-scope="{item, hidden}">
      <tr>
        <td v-if="hidden.name">
          <router-link
            class="text-decoration-none"
            :to="toDetail(item.id)">
            <span :class="{'text-danger': !item.isActive}">
              {{item.name}}
            </span>
          </router-link>
        </td>
        <td v-if="hidden.machine">{{ item.machine.name }}</td>
      </tr>
    </template>

    <router-link
      slot="action"
      v-if="$auth.hasRole(`procedure:${procedureType}:add`)"
      :to="toDetail(0)">
      <button
        type="button"
        class="btn btn-link text-success">
        เพิ่ม
      </button>
    </router-link>
  </sgv-table>
</template>

<script>
import {
  LIST_PROCEDURE
} from './graph'
import retainMixin from '@/mixins/retain-mixin'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.title}`
    }
  },
  props: {
    procedureType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    selectedCategory: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      detailView: `Procedure${this.$form.capitalize(this.procedureType)}Detail`,
      rKey: `Procedure${this.$form.capitalize(this.procedureType)}ListCategory`,
      rFields: ['filter', 'options'],
      procedures: [],
      headers: [
        {text: 'ชื่อ', value: 'name', sort: true, filter: true},
        {text: 'เครื่องจักร', value: 'machine'}
      ],
      filter: {
        limit: 10,
        offset: 0,
        order: null,
        params: null
      },
      options: {
        headers: ['name', 'machine'],
        column: null,
        search: null,
        toolbar: null,
      },
    }
  },
  apollo: {
    procedures: {
      query () {
        return LIST_PROCEDURE(this.templateType)
      },
      variables() {
        this.setRetaining()
        return {
          procedureType: this.procedureType,
          q: this.getFilter(this.filter)
        }
      },
      debounce: 150,
      fetchPolicy: 'network-only'
    }
  },
  methods: {
    getFilter (v) {
      const filter = {...v, params: v.params || {}}
      if (this.selectedCategory === -1) {
        filter.params.isActive = false
        delete filter.params.categoryId
      } else {
        filter.params.isActive = true
        filter.params.categoryId = this.selectedCategory
      }
      return filter
    },
    toDetail (id) {
      return {
        name: this.detailView,
        params: {procedureId: id}
      }
    }
  },
  created () {
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  }
}
</script>

<style lang="css" scoped>
</style>
