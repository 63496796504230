import gql from 'graphql-tag'


const detailResponse = `
  id type name description isActive
  machineId machine {id type code name}
  procedureInventoryId
  procedureControlId
  procedureQualityId
  categories {id name}
`

export const LIST_PROCEDURE = (templateType) => gql`query LIST_PROCEDURE ($procedureType: String!, $q: FilterInput) {
  procedures: listProcedure${templateType} (procedureType: $procedureType, q: $q) {
    id name isActive
    machineId machine {id type code name}
  }
}`

export const DETAIL_PROCEDURE = (templateType) => gql`query DETAIL_PROCEDURE ($procedureType: String!, $procedureId: Int!) {
  procedure: detailProcedure${templateType} (procedureType: $procedureType, procedureId: $procedureId) {${detailResponse}}
}`

export const CREATE_PROCEDURE = (templateType) => gql`mutation CREATE_PROCEDURE ($procedureType: String!, $input: Procedure${templateType}Input!) {
  createProcedure: createProcedure${templateType} (procedureType: $procedureType, input: $input) {${detailResponse}}
}`

export const UPDATE_PROCEDURE = (templateType) => gql`mutation UPDATE_PROCEDURE ($procedureType: String!, $procedureId: Int!, $input: Procedure${templateType}Input!) {
  updateProcedure: updateProcedure${templateType} (procedureType: $procedureType, procedureId: $procedureId, input: $input) {${detailResponse}}
}`

export const DESTROY_PROCEDURE = (templateType) => gql`mutation DESTROY_PROCEDURE ($procedureType: String!, $procedureId: Int!) {
  destroyProcedure: destroyProcedure${templateType} (procedureType: $procedureType, procedureId: $procedureId) {id}
}`
