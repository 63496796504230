import gql from 'graphql-tag'


export const DP_INVENTORIES = (templateType) => gql`query DP_INVENTORIES ($procedureType: String!, $tag: String!, $q: FilterInput) {
  items: listProcedure${templateType}DropdownInventory (procedureType: $procedureType, tag: $tag, q: $q) {
    id type code name
  }
}`

export const DP_INVENTORY_TYPES = (templateType) => gql`query DP_INVENTORY_TYPES ($procedureType: String!, $tag: String!) {
  types: listProcedure${templateType}DropdownInventoryType (procedureType: $procedureType, tag: $tag) {
    text value
  }
}`

export const DP_PROCEDURE_INVENTORY = (templateType) => gql`query DP_PROCEDURE_INVENTORY ($procedureType: String!, $q: FilterInput) {
  items: listProcedure${templateType}DropdownProcedureInventory (procedureType: $procedureType, q: $q) {
    id type code name
  }
}`

export const DP_PROCEDURE_CONTROL = (templateType) => gql`query DP_PROCEDURE_CONTROL ($procedureType: String!, $q: FilterInput) {
  items: listProcedure${templateType}DropdownProcedureControl (procedureType: $procedureType, q: $q) {
    id type code name
  }
}`

export const DP_PROCEDURE_QUALITY = (templateType) => gql`query DP_PROCEDURE_QUALITY ($procedureType: String!, $q: FilterInput) {
  items: listProcedure${templateType}DropdownProcedureQuality (procedureType: $procedureType, q: $q) {
    id type code name
  }
}`
