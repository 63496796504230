import gql from 'graphql-tag'

export const LIST_IMPORT_VALIDATION = (templateType) => gql`query LIST_IMPORT_VALIDATION ($procedureType: String!, $input: [Procedure${templateType}ImportInput]!) {
  result: listProcedure${templateType}ImportValidation (procedureType: $procedureType, input: $input) {
    errors {
      name description
    }
    adds {
      name description
      machineId machine {id type code name}
      procedureInventoryId procedureInventory {id code name}
      procedureControlId procedureControl {id code name}
      procedureQualityId procedureQuality {id code name}
    }
  }
}`
